import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo-custom-fill',
  standalone: true,
  imports: [],
  templateUrl: './logo-custom-fill.component.html',
  styleUrl: './logo-custom-fill.component.scss',
})
export class LogoCustomFillComponent {
  @Input() fillColor: string = 'none';
  @Input() width: number = 705;
  @Input() height: number = 114;
  @Input() isWeddingPage: boolean = false;
  @Input() isGenericPage: boolean = false;
}
