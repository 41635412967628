<div class="email-invite-wrapper">
  @if (isDesktop) {
    <div class="page-header-attendee">
      <div class="page-title">
        {{ "APP.EVENT_INVITE.ENTER_EMAIL" | translate }}
      </div>
      <div class="page-description">
        {{ "APP.EVENT_INVITE.SIMPLY_WRITE_DOWN_AN_EMAIL" | translate }}
      </div>
    </div>
    <form [formGroup]="emailForm" (keydown.enter)="invitePerson()">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
        <input type="email" matInput formControlName="email" />
      </mat-form-field>
      <div class="description-section">
        <div
          class="navigate-to-template"
          (click)="openEditEmailInvitationPage()"
        >
          <span>{{ "APP.EVENT_INVITE.EDIT_INVITE_TEMPLATE" | translate }}</span>
          <i class="far fa-arrow-right"></i>
        </div>
      </div>
    </form>
  }

  <div class="attendee-list-cont">
    <div class="page-header-attendee">
      @if (isDesktop) {
        <div class="page-title">
          {{ "APP.EVENT_INVITE.INVITED" | translate }}
        </div>
      }
      @if (!isDesktop) {
        @if (!isSearchMode) {
          <div class="mobile-header">
            <div class="page-title">
              {{ "APP.EVENT_INVITE.INVITED" | translate }}
            </div>
            <div class="invite-actions">
              <div class="invite-action" (click)="toggleSearch()">
                <i class="far fa-search"></i>
              </div>
              <div class="invite-action" (click)="toggleInvite(false)">
                <i class="far fa-user-plus"></i>
              </div>
            </div>
          </div>
          <!--
          <div class="page-description"> {{ "APP.EVENT_INVITE.PEOPLE_INVITED_TO_EVENT_WILL_APPEAR_HERE" | translate }}</div>
-->
        } @else {
          <div class="mobile-search">
            <div class="invite-action" (click)="toggleSearch()">
              <i class="far fa-times"></i>
            </div>
            <app-search-box
              [filteredProductNames]="filteredAttendeesNames"
              [placeholder]="'APP.EVENT_INVITE.SEARCH_TABLE'"
              (valueChanged)="onSearchTermChange($event)"
            ></app-search-box>
          </div>
        }
      }
    </div>

    <div class="invitation-header">
      <div class="subfilters">
        <swiper-container
          appSwiper
          [config]="swiperTabsConfig"
          init="false"
          class="tabs"
        >
          <swiper-slide
            class="table-filter"
            [class.active-filter]="!filters.selectedStatus"
            (click)="filterRSVPOption(null)"
          >
            {{ "APP.RSVP_OPTION.ALL_GUESTS" | translate }}
          </swiper-slide>
          @for (rsvpType of rsvpTypes; track $index) {
            <swiper-slide
              class="table-filter"
              [class.active-filter]="filters.selectedStatus === rsvpType"
              (click)="filterRSVPOption(rsvpType)"
            >
              {{ getDisplayRSVPOptionName(rsvpType) | translate }}
            </swiper-slide>
          }
        </swiper-container>
      </div>
      @if (isDesktop) {
        <div class="bar-section">
          <div class="invite-action" (click)="toggleSearch()">
            <i class="far fa-search"></i>
          </div>
          @if (isSearchMode) {
            <app-search-box
              class="search-bar w-100"
              [filteredProductNames]="filteredAttendeesNames"
              [placeholder]="'APP.EVENT_INVITE.ENTER_NAME_OR_OTHER'"
              [label]="'APP.EVENT_INVITE.SEARCH_TABLE'"
              (valueChanged)="onSearchTermChange($event)"
            ></app-search-box>
          }
        </div>
      }
    </div>

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let attendee">
            <div class="name-and-picture-col">
              <div class="ellipsis-field fit-content">
                {{ attendee.getName() }}
              </div>
              @if (attendee.checkedIn) {
                <div><i class="fas fa-check-circle checked-in-icon"></i></div>
              }
              @if (attendee.message && attendee.message !== "") {
                <div class="table-badge">1</div>
              }
            </div>
          </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{
            "APP.EMAIL" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let attendee">{{
            attendee.getEmail() || "-"
          }}</mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>{{
            "APP.EVENT_RSVP.STATUS" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let attendee">
            <div class="status-icon">
              <span
                [ngStyle]="{
                  background: EventAttendeeStatus.getRSVPIconColor(
                    attendee.status
                  ),
                  fontSize: '24px'
                }"
              ></span>
              {{
                EventAttendeeStatus.getRSVPDisplayName(attendee.status)
                  | translate
              }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let attendee">
            @if (attendee.status === EventAttendeeStatus.INVITED) {
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                class="more-vert-rotate-90"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="table-actions-container">
                <button mat-menu-item (click)="retrieveInvite(attendee)">
                  <span class="menu-item-content">
                    <i class="far fa-redo"></i>
                    <span class="text">
                      {{ "APP.EVENT_INVITE.RETRIEVE_INVITE" | translate }}</span
                    >
                  </span>
                </button>
              </mat-menu>
            }
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [hidden]="dataSource.loading$ | async"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-row" [colSpan]="displayedColumns.length">
            <div class="no-data-row-overlay"></div>
            <div class="no-data-row-cont">
              <div class="no-data-emoji">🙁</div>
              <div class="no-data-title">
                {{ "APP.EVENT_RSVP.TABLE_NO_GUESTS" | translate }}
              </div>
              <div class="no-data-desc">
                {{ "APP.EVENT_RSVP.TABLE_NO_GUESTS_DESC" | translate }}
              </div>
            </div>
          </td>
        </tr>

        <div
          class="spinner-container d-flex justify-content-center"
          *ngIf="dataSource.loading$ | async"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-table>
    </div>

    <app-paginator
      [totalItems]="dataSource.numRecords$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (changePage)="onPageChange($event)"
    >
    </app-paginator>
  </div>

  @if (!isDesktop && showMobileInvite) {
    <app-invite-guests
      [emailForm]="emailForm"
      (openInvitationPage)="openEditEmailInvitationPage()"
      (close)="toggleInvite($event)"
    />
  }
</div>
