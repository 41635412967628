import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { EventInviteRequest } from '@models/events/dto/event-invite.request';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-invite-guests',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './invite-guests.component.html',
  styleUrl: './invite-guests.component.scss',
  providers: [FormsModules, NotificationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InviteGuestsComponent implements OnInit, AfterViewInit {
  isFullScreen = false;
  searchMode: boolean = false;
  event?: Event;
  isExpanded = true;
  isDialogOpen = false;
  @Input() emailForm?: FormGroup;

  @Output() close = new EventEmitter<boolean>();
  @Output() openInvitationPage = new EventEmitter<void>();

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private eventStore: EventStore,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
    });
  }

  onOpenInvitationPage() {
    this.openInvitationPage.emit();
    this.isDialogOpen = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.el.nativeElement.querySelector('.change-items-cont');
      if (element) {
        this.renderer.addClass(element, 'open');
      }
    }, 50);
  }
  invitePerson() {
    if (!this.emailForm || !this.event) {
      return;
    }
    if (this.emailForm.valid) {
      const value = this.emailForm.value.email;

      const req: EventInviteRequest = {
        email: value,
      };

      this.eventService.inviteGuests(this.event.id, req).subscribe({
        next: () => {
          if (this.event) {
            this.onClose(true);
          }
        },
        error: (err) => {
          this.notificationService.error(err.error);
        },
      });

      this.emailForm.reset();
    }
  }

  onClose(refresh?: boolean) {
    this.close.emit(refresh);
  }

  setIsExpanded(isExpanded: boolean) {
    if (!isExpanded) {
      this.isExpanded = false;
      setTimeout(() => {
        this.onClose();
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.isDialogOpen = true;
    this.isExpanded = true;
  }
}
