<div
  class="change-items-cont event-action-dialog"
  [class.expanded]="isExpanded"
  [class.collapsed]="!isExpanded"
  (swipeup)="setIsExpanded(true)"
  (swipedown)="setIsExpanded(false)"
>
  <div class="dialog-header">
    <div class="swiper-indicator-section">
      <div class="swipe-indicator"></div>
    </div>
  </div>

  <div class="dialog-body">
    <div class='content'>
      @if(emailForm){
        <div class='page-header-attendee'>
          <div class="page-title"> {{ "APP.EVENT_INVITE.INVITE_PEOPLE" | translate }}</div>
          <div class="page-description"> {{ "APP.EVENT_INVITE.INVITE_PEOPLE_TO_YOUR_EVENT" | translate }}</div>
        </div>
        <form [formGroup]="emailForm">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "APP.EMAIL" | translate }}</mat-label>
            <input type="email" matInput formControlName="email" placeholder='lasseschmitt@gmail.com' />
          </mat-form-field>
          <button class='btn btn-primary btn-send-invitation' (click)='invitePerson()'>
            {{ "APP.EVENT_INVITE.SEND_INVITATION" | translate }}
          </button>
          <div class='navigate-to-template' (click)='onOpenInvitationPage()'>
            <span>{{ "APP.EVENT_INVITE.EDIT_INVITE_TEMPLATE" | translate }}</span>
          </div>
        </form>
      }
    </div>

  </div>
</div>
