import { CommonModule, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ChangeDetectorRef,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EventAttendeeFeDataSource } from '@ds/event-attendee-fe.ds';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { UpdateEventAttendeeRsvpOptionRequest } from '@models/events/dto/update-event-attendee-rsvp-option.request';
import { Router } from '@angular/router';
import { Event } from '@models/events/event.model';
import { AttendeeListFilters } from '@modules/events/components/attendee-list-advanced/attendee-list-advanced.component';
import { InviteGuestsComponent } from '@modules/events/components/invite-guests/invite-guests.component';
import { AddGuestDialog } from '@modules/events/dialogs/add-guest/add-guest.dialog';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { EventInviteRequest } from '@models/events/dto/event-invite.request';
import { Location } from '@angular/common';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { SidebarService } from '@services/sidebar.service';
import { EventStore } from '@services/stores/event.store';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { FormsModules } from '@utils/shared-modules';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../../../directives/swiper.directive';

@Component({
  selector: 'app-invite-email',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBoxComponent,
    PaginatorComponent,
    SwiperDirective,
    InviteGuestsComponent,
  ],
  providers: [EventService],
  templateUrl: './invite-email.component.html',
  styleUrl: './invite-email.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InviteEmailComponent implements AfterViewInit {
  event?: Event;
  emailForm: FormGroup;
  @Output() invite = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;

  dataSource: EventAttendeeFeDataSource;

  displayedColumnsDefault: string[] = ['name', 'email', 'status', 'actions'];

  displayedColumns = this.displayedColumnsDefault;
  showMobileInvite?: boolean = false;

  filters: AttendeeListFilters = {
    searchTerm: '',
    selectedStatus: null,
  };
  rsvpTypes = RSVPOptionType.getAllTypes();
  filteredAttendeesNames: string[] = [];
  isServer = false;
  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100];
  refreshAttendeesOnEventRefresh = false;
  isDesktop?: boolean;
  isSearchMode?: boolean = false;
  swiperTabsConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 1000,

    spaceBetween: 8,
    injectStyles: [
      `
        :host .swiper { overflow: visible;}
      `,
    ],
  };

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private router: Router,
    private location: Location,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private eventStore: EventStore,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Object,
    private sidebarService: SidebarService,
    private notificationService: NotificationService,
    private screenWidthService: ScreenWidthService,
    private eRef: ElementRef,
  ) {
    this.isServer = isPlatformServer(platformId);
    this.dataSource = new EventAttendeeFeDataSource();
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;

      if (this.refreshAttendeesOnEventRefresh) {
        this.refreshAttendeesOnEventRefresh = false;
        this.resetPageIndex();
        this.refresh();
      }
    });

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => (this.isDesktop = isDesktop));
  }

  toggleInvite(refresh: boolean) {
    this.showMobileInvite = !this.showMobileInvite;
    if (refresh && this.event) {
      this.refreshAttendeesOnEventRefresh = true;
      this.eventStore.refreshEvent(this.event.uri);
    }
  }

  invitePerson() {
    if (!this.emailForm || !this.event) {
      return;
    }
    if (this.emailForm.valid) {
      const value = this.emailForm.value.email;

      const req: EventInviteRequest = {
        email: value,
      };

      this.eventService.inviteGuests(this.event.id, req).subscribe({
        next: () => {
          if (this.event) {
            this.refreshAttendeesOnEventRefresh = true;
            this.eventStore.refreshEvent(this.event.uri);
          }
        },
        error: (err) => {
          this.notificationService.error(err.error);
        },
      });

      this.emailForm.reset();
    }
  }

  toggleSearch() {
    this.isSearchMode = !this.isSearchMode;
    if (!this.isSearchMode) {
      this.filters.searchTerm = '';
      this.refresh();
    }
  }

  ngAfterViewInit() {
    if (!this.isServer) {
      this.sort.sortChange.subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });

      this.refresh();

      this.filterAttendees();
      this.cd.detectChanges();
    }
  }

  refresh() {
    let filteredAttendees =
      this.event?.attendees?.filter((x) => x.isInvited) || [];
    if (this.filters.searchTerm) {
      const searchTerm = this.filters.searchTerm.toLowerCase();
      filteredAttendees = filteredAttendees.filter(
        (attendee) =>
          attendee.getName().toLowerCase().includes(searchTerm) ||
          (attendee.getEmail() &&
            attendee.getEmail().toLowerCase().includes(searchTerm)),
      );
    }

    if (this.filters.selectedStatus) {
      if (this.filters.selectedStatus === RSVPOptionType.WAITLIST) {
        filteredAttendees =
          this.event?.getAttendeesByStatus(EventAttendeeStatus.ON_WAITLIST) ||
          [];
      } else if (
        this.filters.selectedStatus === RSVPOptionType.PENDING_APPROVAL
      ) {
        filteredAttendees =
          this.event?.getAttendeesByStatus(
            EventAttendeeStatus.PENDING_APPROVAL,
          ) || [];
      } else if (this.filters.selectedStatus === RSVPOptionType.INVITED) {
        filteredAttendees =
          this.event?.getAttendeesByStatus(EventAttendeeStatus.INVITED) || [];
      } else {
        filteredAttendees =
          this.event?.getAttendees(this.filters.selectedStatus) || [];
      }
    }

    if (!!this.sort.direction) {
      if (this.sort.active === 'name') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getName().localeCompare(b.getName()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getName().localeCompare(a.getName()),
          );
        }
      } else if (this.sort.active === 'email') {
        if (this.sort.direction === 'asc') {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            a.getEmail().localeCompare(b.getEmail()),
          );
        } else {
          filteredAttendees = filteredAttendees.sort((a, b) =>
            b.getEmail().localeCompare(a.getEmail()),
          );
        }
      }
    }

    filteredAttendees = filteredAttendees.filter((x) => x.isInvited);

    this.dataSource.filterEventAttendees(
      filteredAttendees,
      new Map(),
      this.paginator && this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator?.pageSize,
    );
  }

  onSearchTermChange(searchTerm: string) {
    this.filters.searchTerm = searchTerm;
    this.filterAttendees();
    this.resetPageIndex();
    this.refresh();
  }

  filterAttendees() {
    this.filteredAttendeesNames = (
      this.event?.attendees?.filter(
        (attendee) => attendee.isInvited && attendee.getName() !== '',
      ) ?? []
    )
      .filter(
        (attendee) =>
          attendee
            .getName()
            .toLowerCase()
            .indexOf(this.filters.searchTerm.toLowerCase()) >= 0,
      )
      .map((attendee) => attendee.getName());
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  selectRSVPOption(attendee: EventAttendee, status: RSVPOptionType): void {
    const attendeeRsvpType =
      attendee.status === EventAttendeeStatus.PENDING_APPROVAL
        ? RSVPOptionType.PENDING_APPROVAL
        : attendee.rsvpType;
    if (attendeeRsvpType !== status) {
      const dto: UpdateEventAttendeeRsvpOptionRequest = {
        attendeeId: attendee.id,
        rsvpOption: status,
      };

      if (attendeeRsvpType === RSVPOptionType.PENDING_APPROVAL) {
        if (status === RSVPOptionType.YES) {
          dto.approve = true;
        } else if (status === RSVPOptionType.NO) {
          dto.approve = false;
        }
      }

      this.eventService
        .updateAttendeeRSVPOption(this.event?.id, dto)
        .subscribe((result) => {
          this.event ? (this.event.attendees = result) : undefined;
          this.refresh();
        });
    }
  }

  checkIn(attendee: EventAttendee, checkIn: boolean): void {
    if (!this.event) {
      return;
    }

    const request: UpdateEventAttendeeRsvpOptionRequest = {
      attendeeId: attendee.id,
      checkedIn: checkIn,
    };

    this.eventService
      .updateAttendeeRSVPOption(this.event.id, request)
      .subscribe({
        next: (result) => {
          this.event ? (this.event.attendees = result) : undefined;
          this.refresh();
        },
        error: (err) => {
          this.notificationService.error(
            this.translateService.instant('APP.ERRORS.COULD_NOT_SAVE'),
          );
        },
      });
  }

  retrieveInvite(attendee: EventAttendee): void {
    if (!this.event) {
      return;
    }

    this.eventService.deleteAttendee(attendee.eventId, attendee.id).subscribe({
      next: () => {
        this.event!.attendees = this.event!.attendees?.filter(
          (att) => att.id !== attendee.id,
        );
        this.resetPageIndex();
        this.refresh();
      },
      error: () => {
        this.notificationService.error(
          this.translateService.instant('APP.ERRORS.COULD_NOT_DELETE'),
        );
      },
    });
  }

  filterRSVPOption(status: RSVPOptionType | null): void {
    this.filters.selectedStatus = status;
    this.resetPageIndex();
    this.refresh();
  }

  addGuest() {
    if (!this.event) {
      return;
    }

    const dialogRef = this.dialog.open(AddGuestDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (res.refresh) {
          this.refreshAttendeesOnEventRefresh = true;
          this.eventStore.refreshEvent(this.event.uri);
        }
      }
    });
  }
  getDisplayRSVPOptionName(status: RSVPOptionType): string {
    if (status === RSVPOptionType.PENDING_APPROVAL) {
      return 'APP.RSVP_OPTION.PENDING';
    }

    const rsvpOption = this.event?.rsvpOptions?.find(
      (rsvpOption) => rsvpOption.type === status,
    );
    if (rsvpOption) {
      return rsvpOption.name;
    }
    return RSVPOptionType.getRSVPDisplayName(status);
  }

  getAttendeeDisplayRSVPOptionName(attendee: EventAttendee): string {
    if (attendee.status === EventAttendeeStatus.ON_WAITLIST) {
      return 'APP.RSVP_OPTION.WAITLIST';
    } else if (attendee.status === EventAttendeeStatus.PENDING_APPROVAL) {
      return 'APP.RSVP_OPTION.PENDING_APPROVAL';
    }

    const rsvpOption = this.event?.rsvpOptions?.find(
      (rsvpOption) => rsvpOption.type === attendee.rsvpType,
    );
    if (rsvpOption) {
      return rsvpOption.name;
    }
    return RSVPOptionType.getDisplayName(attendee.rsvpType);
  }

  getNumAttendeesWithRSVPType(rsvpType: RSVPOptionType): number {
    if (!this.event) {
      return 0;
    }
    if (rsvpType === RSVPOptionType.PENDING_APPROVAL) {
      return (
        this.event.getAttendeesByStatus(EventAttendeeStatus.PENDING_APPROVAL)
          ?.length || 0
      );
    } else if (rsvpType === RSVPOptionType.WAITLIST) {
      return (
        this.event.getAttendeesByStatus(EventAttendeeStatus.ON_WAITLIST)
          ?.length || 0
      );
    } else if (rsvpType === RSVPOptionType.INVITED) {
      return (
        this.event.getAttendeesByStatus(EventAttendeeStatus.INVITED)?.length ||
        0
      );
    }

    return this.event.getAttendees(rsvpType)?.length || 0;
  }

  get numAttendees(): number {
    return this.event?.attendees?.length || 0;
  }

  get tableRsvpTypes(): RSVPOptionType[] {
    return this.rsvpTypes.filter(
      (rsvpType) =>
        rsvpType !== RSVPOptionType.CHECKED &&
        rsvpType !== RSVPOptionType.INVITED,
    );
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  protected readonly EventAttendeeStatus = EventAttendeeStatus;
  protected readonly RSVPOptionType = RSVPOptionType;

  openEditEmailInvitationPage() {
    this.invite.emit();
  }
}
